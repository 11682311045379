import {
  HTTP_STATUS_CONFLICT,
  HTTP_STATUS_FORBIDDEN,
  HTTP_STATUS_INTERNAL_SERVER_ERROR,
  HTTP_STATUS_PAGE_EXPIRED,
  HTTP_STATUS_PAYMENT_REQUIRED,
  HTTP_STATUS_SERVICE_UNAVAILABLE,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_LOCKED,
  // MODAL_SITE_ALERT,
  // MODAL_SITE_LOGIN,
  // MODAL_SITE_SIGNUP,
  // MODAL_CONFIRM_PASSWORD
} from "./Constants";
import store from "@/store";
import api from "@/services/api";
import { getCompleteUrl } from "@/utils/History";
// import { alerts } from "@Utils/Alerts";

export const httpActions = {
  [HTTP_STATUS_UNAUTHORIZED]: async (error) => {
    if (getCompleteUrl() != "/login") {
      store.dispatch("modals/showUnathorizedModal");
    }
    store.commit("retry/setNextRequest", error.config);
  },
  [HTTP_STATUS_LOCKED]: async (error) => {
    // store.dispatch("modals/showModal", { name: MODAL_CONFIRM_PASSWORD, prioritized: true });
    store.commit("retry/setNextRequest", error.config);
  },
  [HTTP_STATUS_PAYMENT_REQUIRED]: () => {
    // store.dispatch("modals/showModal", {
    //     name: MODAL_SITE_ALERT,
    //     alertName: "INSUFFICIENT_FUNDS",
    //     prioritized: true
    // });
  },
  [HTTP_STATUS_FORBIDDEN]: (error) => {
    const { message } = error.response.data;
    // console.log(param);

    // if (!store.getters.isLogged) {
    //     store.dispatch("modals/showModal", MODAL_SITE_SIGNUP);
    //     return;
    // }

    // const hasAlert = alerts.hasOwnProperty(message);
    // let alertParams = {
    //     name: MODAL_SITE_ALERT,
    //     alertName: message,
    //     prioritized: true,
    //     param
    // };

    // if (hasAlert) {
    //     store.dispatch("modals/showModal", alertParams);
    //     return;
    // }

    store.dispatch("notifications/pushErrorToList", {
      message,
    });
  },
  [HTTP_STATUS_CONFLICT]: async (error) => {
    await api.get("refresh-token");
    api.request(error.config).then((response) => {
      store.dispatch("auth/setLoggedStatic", response.data.user);
    });
  },
  [HTTP_STATUS_PAGE_EXPIRED]: async (error) => {
    await api.get("refresh-token");
    api.request(error.config).then((response) => {
      store.dispatch("auth/setLoggedStatic", response.data.user);
    });
  },
  [HTTP_STATUS_SERVICE_UNAVAILABLE]: () => {
    // console.log(error);
    location.reload();
  },
  [HTTP_STATUS_INTERNAL_SERVER_ERROR]: (error) => {
    const { message } = error.response.data;
    store.dispatch("notifications/pushErrorToList", {
      message,
    });
  },
};
