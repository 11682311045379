<template>
  <travelfy-template />
</template>

<script>
import Page from "@/mixins/page";

export default {
  mixins: [Page],
};
</script>

<style lang="scss" module src="@/utils/scss/App.scss" />
