import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import system from "@/store/modules/System";
import retry from "@/store/modules/Retry";
import user from "@/store/modules/User";
import recharge from "@/store/modules/Recharge";
import application from "@/store/modules/Application";
import modals from "@/store/modules/Modals";
import state from "@/store/state";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

export default createStore({
  state,
  mutations,
  getters,
  modules: {
    retry,
    user,
    application,
    modals,
    system,
    recharge,
  },
  strict: false,
  plugins: [
    createPersistedState({
      paths: ["user", "system"],
    }),
  ],
});
