import api from "@/services/api";
import router from "@/router";

export default {
  async login({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      return api
        .post("/auth", data)
        .then((response) => {
          const { licenses, access_token, user } = response.data;
          commit("setUser", user);
          commit("setAccessToken", access_token);
          commit("system/setAmountSubscriptionAvailable", licenses, {
            root: true,
          });
          commit("setIsLogged");
          commit("isLoading", false);
          api.defaults.headers.Authorization = `Bearer ${access_token}`;
          window.sessionStorage.setItem("access_token", access_token);
          resolve(response.data);
        })
        .catch((error) => {
          commit("isLoading", false);
          reject(error);
        });
    });
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("setUser", {});
      commit("setAccessToken", null);
      commit("setIsLogged", false);
      window.sessionStorage.removeItem("access_token");
      window.localStorage.removeItem("vuex");
      router.push("/login");
      resolve(true);
    });
  },
  async refreshLicenses({ commit }) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/user/licenses`)
        .then((result) => {
          commit("isLoading", false);
          const { licenses } = result.data;
          commit("system/setAmountSubscriptionAvailable", licenses, {
            root: true,
          });
          resolve(licenses);
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async getAllUsers({ commit }, params) {
    commit("isLoading");
    return new Promise((resolve, reject) => {
      return api
        .get(`/manager/users`, { params })
        .then((result) => {
          commit("isLoading", false);
          commit("setUsers", result.data.items);
          commit("setPagination", result.data.meta);
          resolve(result);
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async updatePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      return api
        .post("/user/update-password", data)
        .then((response) => {
          commit("isLoading", false);
          resolve(response.data);
        })
        .catch((error) => {
          commit("isLoading", false);
          reject(error);
        });
    });
  },
  async recoverPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      return api
        .post("/auth/recover-password", data)
        .then((response) => {
          commit("isLoading", false);
          resolve(response.data);
        })
        .catch((error) => {
          commit("isLoading", false);
          reject(error);
        });
    });
  },
  async verifyWelcomeUser(_, userId) {
    return new Promise((resolve, reject) => {
      return api
        .request({
          url: `/user/${userId}/welcome`,
          method: "GET",
        })
        .then((response) => {
          resolve({ status: response.data.welcomeShown });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async alterWelcomeUser(_, userId) {
    return new Promise((resolve, reject) => {
      return api
        .request({
          url: `/user/${userId}/welcome`,
          method: "PATCH",
        })
        .then(() => {
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async verifyTermsUser(_, userId) {
    return new Promise((resolve, reject) => {
      return api
        .request({
          url: `/user/${userId}/terms`,
          method: "GET",
        })
        .then((response) => {
          resolve({ status: response.data.acceptedTerms });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async alterTermsUser(_, userId) {
    return new Promise((resolve, reject) => {
      return api
        .request({
          url: `/user/${userId}/terms`,
          method: "PATCH",
        })
        .then(() => {
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
};
