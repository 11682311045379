export const IS_MOBILE =
  "(iPhone|iPod|iPad|Android|BlackBerry|IEMobile|Opera Mini)";
export const IS_IOS = "(iPhone|iPod|iPad)";
export const IS_FIREFOX = "(Firefox)";
export const IS_DESKTOP =
  "^((?!iPhone|iPod|iPad|Android|BlackBerry|IEMobile|Opera Mini).)*$";
export const IS_MAC = "(Macintosh)";

export const BR_LOCALE = "br";
export const EN_LOCALE = "en";

export const GOOGLE_LOCALE = {
  [BR_LOCALE]: "pt-BR",
  [EN_LOCALE]: "en-US",
};

export const MOMENT_LOCALE = {
  [BR_LOCALE]: "pt-br",
  [EN_LOCALE]: "en",
};

export const PARAMS_SERIALIZER_DEFAULT_OPTIONS = {
  arrayFormat: "comma",
  encode: false,
  skipNulls: true,
};
export const QUERY_PARAMS_LIMIT = 30;

export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_PAYMENT_REQUIRED = 402;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_CONFLICT = 409;
export const HTTP_STATUS_PAGE_EXPIRED = 419;
export const HTTP_STATUS_LOCKED = 423;
export const HTTP_STATUS_SERVICE_UNAVAILABLE = 503;
export const HTTP_STATUS_INTERNAL_SERVER_ERROR = 503;
export const HTTP_STATUS_SUCCESS_WITHOUT_BODY = 204;

export const MENU_LOGOUT = "logout";

export const STATUS_SAVED = 0;

export const STATUS_PROCESSING = 1;

export const STATUS_PROCESSED = 2;

export const STATUS_ERROR = 3;

export const STATUS_IN_REVIEW = 5;

export const DEFAULT_MAX_ADD_AMOUNT = 5;

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const WITHOUT_FAMILY = ["W", "D", "L"];

export const TIMEOUT_CAPTCHA_IN_SECONDS = 30;

export const STATES = {
  AL: "ALABAMA",
  AK: "ALASKA",
  AS: "AMERICAN SAMOA",
  AZ: "ARIZONA",
  AR: "ARKANSAS",
  CA: "CALIFÓRNIA",
  CO: "COLORADO",
  CT: "CONNECTICUT",
  DE: "DELAWARE",
  DC: "DISTRITO DA COLÔMBIA",
  FL: "FLORIDA",
  GA: "GEORGIA",
  GU: "GUAM",
  HI: "HAWAII",
  ID: "IDAHO",
  IL: "ILLINOIS",
  IN: "INDIANA",
  IA: "IOWA",
  KS: "KANSAS",
  KY: "KENTUCKY",
  LA: "LOUISIANA",
  ME: "MAINE",
  MD: "MARYLAND",
  MA: "MASSACHUSETTS",
  MI: "MICHIGAN",
  MN: "MINNESOTA",
  MS: "MISSISSIPPI",
  MO: "MISSOURI",
  MT: "MONTANA",
  NE: "NEBRASKA",
  NV: "NEVADA",
  NH: "NEW HAMPSHIRE",
  NJ: "NEW JERSEY",
  NM: "NOVO MÉXICO",
  NY: "NEW YORK",
  NC: "NORTH CAROLINA",
  ND: "NORTH DAKOTA",
  MP: "NORTHERN MARIANA ISLANDS",
  OH: "OHIO",
  OK: "OKLAHOMA",
  OU: "OREGON",
  PA: "PENSILVÂNIA",
  PR: "PUERTO RICO",
  RI: "RHODE ISLAND",
  SC: "SOUTH CAROLINA",
  SD: "SOUTH DAKOTA",
  TN: "TENNESSEE",
  TX: "TEXAS",
  UT: "UTAH",
  VT: "VERMONT",
  VI: "ILHAS VIRGENS",
  VA: "VIRGINIA",
  WA: "WASHINGTON",
  WV: "WEST VIRGINIA",
  WI: "WISCONSIN",
  WY: "WYOMING",
};

export const COUNTRIES = {
  AFGH: "AFGHANISTAN",
  ALB: "ALBANIA",
  ALGR: "ARGÉLIA",
  ASMO: "AMERICAN SAMOA",
  ANDO: "ANDORRA",
  ANGL: "ANGOLA",
  ANGU: "ANGUILLA",
  ANTI: "ANTIGUA E BARBUDA",
  ARG: "ARGENTINA",
  ARM: "ARMENIA",
  ARB: "ARUBA",
  ASTL: "AUSTRALIA",
  AUST: "ÁUSTRIA",
  AZR: "AZERBAIJAN",
  BAMA: "BAHAMAS",
  BAHR: "BAHRAIN",
  BANG: "BANGLADESH",
  BRDO: "BARBADOS",
  BYS: "BELARUS",
  BELG: "BELGIUM",
  BLZ: "BELIZE",
  BENN: "BENIN",
  BERM: "BERMUDA",
  BHU: "BHUTAN",
  BOL: "BOLIVIA",
  BON: "BONAIRE",
  BIH: "BOSNIA-HERZEGOVINA",
  BOT: "BOTSWANA",
  BRZL: "BRASIL",
  IOT: "TERRITÓRIO DO OCEANO ÍNDICO BRITÂNICO",
  BRNI: "BRUNEI",
  BULG: "BULGARIA",
  BURK: "BURKINA FASO",
  BURM: "BURMA",
  BRND: "BURUNDI",
  CBDA: "CAMBODIA",
  CMRN: "CAMEROON",
  CAN: "CANADA",
  CAVI: "CABO VERDE",
  CAYI: "ILHAS DE CAYMAN",
  CAFR: "REPÚBLICA CENTRAL AFRICANA",
  CHAD: "CHAD",
  CHIL: "CHILE",
  CHIN: "CHINA",
  CHRI: "ILHA DE NATAL",
  COCI: "COCOS KEELING ISLANDS",
  COL: "COLOMBIA",
  COMO: "COMOROS",
  COD: "CONGO, REPÚBLICA DEMOCRÁTICA DO",
  CONB: "CONGO, REPÚBLICA DO",
  CKIS: "COOK ISLANDS",
  CSTR: "COSTA RICA",
  IVCO: "COTE D`IVOIRE",
  HRV: "CROATIA",
  CUBA: "CUBA",
  CUR: "CURACAO",
  CYPR: "CHIPRE",
  CZEC: "REPÚBLICA CHECA",
  DEN: "DINAMARCA",
  DJI: "DJIBOUTI",
  DOMN: "DOMINICA",
  DOMR: "REPÚBLICA DOMINICANA",
  ECUA: "EQUADOR",
  EGYP: "EGYPT",
  ELSL: "EL SALVADOR",
  EGN: "GUINÉ EQUATORIAL",
  ERI: "ERITREA",
  EST: "ESTONIA",
  SZLD: "ESWATINI",
  ETH: "ETIÓPIA",
  FKLI: "ILHAS FALKLAND",
  FRO: "FAROE ISLANDS",
  FIJI: "FIJI",
  FIN: "FINLAND",
  FRAN: "FRANÇA",
  FRGN: "FRENCH GUIANA",
  FPOL: "POLINÉSIA FRANCESA",
  FSAT: "TERRITÓRIOS FRANCESES DO SUL E DA ANTÁRTICA",
  GABN: "GABON",
  GAM: "GAMBIA, THE",
  XGZ: "GAZA STRIP",
  GEO: "GEORGIA",
  GER: "ALEMANHA",
  GHAN: "GHANA",
  GIB: "GIBRALTAR",
  GRC: "GRÉCIA",
  GRLD: "GREENLAND",
  GREN: "GRENADA",
  GUAD: "GUADELOUPE",
  GUAM: "GUAM",
  GUAT: "GUATEMALA",
  GNEA: "GUINEA",
  GUIB: "GUINÉ - BISSAU",
  GUY: "GUYANA",
  HAT: "HAITI",
  HMD: "ILHAS OUVIDAS E MCDONALD",
  VAT: "SANTA SÉ (CIDADE DO VATICANO)",
  HOND: "HONDURAS",
  HNK: "HONG KONG",
  XHI: "ILHA DE HOWLAND",
  HUNG: "HUNGRIA",
  ICLD: "ISLÂNDIA",
  IND: "INDIA",
  IDSA: "INDONÉSIA",
  IRAN: "IRAN",
  IRAQUE: "IRAQUE",
  IRE: "IRELAND",
  ISRL: "ISRAEL",
  ITLY: "ITÁLIA",
  JAM: "JAMAICA",
  JPN: "JAPÃO",
  JRSM: "JERUSALEM",
  JORD: "JORDAN",
  KAZ: "KAZAKHSTAN",
  KENY: "KENYA",
  KIRI: "KIRIBATI",
  PRK: "CORÉIA, REPÚBLICA DEMOCRÁTICA DO (NORTE)",
  KOR: "COREIA, REPÚBLICA DO (SUL)",
  KSV: "KOSOVO",
  KUWT: "KUWAIT",
  KGZ: "KYRGYZSTAN",
  LAOS: "LAOS",
  LATV: "LATVIA",
  LEBN: "LÍBANO",
  LES: "LESOTHO",
  LIBR: "LIBERIA",
  LBYA: "LIBYA",
  LCHT: "LIECHTENSTEIN",
  LITH: "LITHUANIA",
  LXM: "LUXEMBOURG",
  MAC: "MACAU",
  MKD: "MACEDÓNIA, NORTE",
  MADG: "MADAGASCAR",
  MALW: "MALAWI",
  MLAS: "MALAYSIA",
  MLDI: "ILHA DE MALDEN",
  MLDV: "MALDIVES",
  MALI: "MALI",
  MLTA: "MALTA",
  RMI: "MARSHALL ISLANDS",
  MART: "MARTINIQUE",
  MAUR: "MAURITANIA",
  MRTS: "MAURITIUS",
  MYT: "MAYOTTE",
  MEX: "MEXICO",
  FSM: "MICRONESIA",
  MDWI: "MIDWAY ISLANDS",
  MLD: "MOLDOVA",
  MON: "MONACO",
  MONG: "MONGOLIA",
  MTG: "MONTENEGRO",
  MONT: "MONTSERRAT",
  MORO: "MOROCCO",
  MOZ: "MOZAMBIQUE",
  NAMB: "NAMIBIA",
  NAU: "NAURU",
  NEP: "NEPAL",
  NETH: "HOLANDA",
  NCAL: "NOVA CALEDÔNIA",
  NZLD: "NOVA ZELÂNDIA",
  NIC: "NICARAGUA",
  NIR: "NIGER",
  NRA: "NIGÉRIA",
  NIUE: "NIUE",
  NFK: "NORFOLK ISLAND",
  MNP: "NORTH MARIANA ISLANDS",
  NIRE: "IRLANDA DO NORTE",
  NORW: "NORUEGA",
  OMAN: "OMAN",
  PKST: "PAQUISTÃO",
  PALA: "PALAU",
  PLMR: "PALMYRA ATOLL",
  PAN: "PANAMA",
  PNG: "PAPUA NOVA GUINÉ",
  PARA: "PARAGUAI",
  PERU: "PERU",
  PHIL: "FILIPINAS",
  PITC: "PITCAIRN ISLANDS",
  POL: "POLAND",
  PORT: "PORTUGAL",
  PR: "PUERTO RICO",
  QTAR: "QATAR",
  REUN: "REUNION",
  ROM: "ROMANIA",
  RUS: "RÚSSIA",
  RWND: "RWANDA",
  SABA: "SABA ISLAND",
  MAF: "SAINT MARTIN",
  WSAM: "SAMOA",
  SMAR: "SAN MARINO",
  STPR: "SAO TOME AND PRINCIPE",
  SARB: "SAUDI ARABIA",
  SENG: "SENEGAL",
  SBA: "SÉRVIA",
  SEYC: "SEYCHELLES",
  SLEO: "SIERRA LEONE",
  SING: "CINGAPURA",
  STM: "SINT MAARTEN",
  SVK: "SLOVAKIA",
  SVN: "ESLOVÊNIA",
  SLMN: "ILHAS DE SALOMÃO",
  SOMA: "SOMALIA",
  SAFR: "ÁFRICA DO SUL",
  SGS: "GEÓRGIA DO SUL E A ILHA DE SANDUÍCHE DO SUL",
  SSDN: "SUDÃO DO SUL",
  SPN: "ESPANHA",
  SRL: "SRI LANKA",
  STEU: "ST. EUSTATIUS",
  SHEL: "ST. HELENA",
  STCN: "ST. KITTS AND NEVIS",
  SLCA: "ST. LUCIA",
  SPMI: "ST. PIERRE AND MIQUELON",
  STVN: "SÃO VINCENTO E GRANADINAS",
  SUDA: "SUDÃO",
  SURM: "SURINAME",
  SJM: "SVALBARD",
  SWDN: "SUÉCIA",
  SWTZ: "SWITZERLAND",
  SYR: "SYRIA",
  TWAN: "TAIWAN",
  TJK: "TAJIKISTAN",
  TAZN: "TANZANIA",
  THAI: "TAILÂNDIA",
  TMOR: "TIMOR-LESTE",
  TOGO: "TOGO",
  TKL: "TOKELAU",
  TONG: "TONGA",
  TRIN: "TRINIDAD AND TOBAGO",
  TNSA: "TUNÍSIA",
  TRKY: "TURKEY",
  TKM: "TURKMENISTAN",
  TCIS: "TURKS AND CAICOS ISLANDS",
  TUV: "TUVALU",
  UGAN: "UGANDA",
  UKR: "UCRÂNIA",
  UAE: "EMIRADOS ÁRABES UNIDOS",
  GRBR: "REINO UNIDO",
  EUA: "ESTADOS UNIDOS DA AMÉRICA",
  URU: "URUGUAI",
  UZB: "UZBEKISTAN",
  VANU: "VANUATU",
  VENZ: "VENEZUELA",
  VTNM: "VIETNAM",
  VI: "VIRGIN ISLANDS (U.S.)",
  BRVI: "VIRGIN ISLANDS, BRITISH",
  WKI: "WAKE ISLAND",
  WAFT: "WALLIS E FUTUNA ISLANDS",
  XWB: "WEST BANK",
  SSAH: "WESTERN SAHARA",
  YEM: "YEMEN",
  ZAMB: "ZAMBIA",
  ZIMB: "ZIMBABWE",
};

export const AVAILABLE_CONSULS = {
  BRA: "BRASIL [Brasília]",
  PTA: "BRASIL [Porto Alegre]",
  RCF: "BRASIL [Recife]",
  RDJ: "BRASIL [Rio de Janeiro]",
  SPL: "BRASIL [São Paulo]",
  TIA: "ALBANIA [TIRANA]",
  ALG: "ARGÉLIA [ALGIERS]",
  LUA: "ANGOLA [LUANDA]",
  BNS: "ARGENTINA [BUENOS AIRES]",
  YRV: "ARMENIA [YEREVAN]",
  MLB: "AUSTRALIA [MELBOURNE]",
  PRT: "AUSTRALIA [PERTH]",
  SYD: "AUSTRALIA [SYDNEY]",
  VNN: "ÁUSTRIA [VIENA]",
  BKU: "AZERBAIJAN [BAKU]",
  NSS: "BAHAMAS [NASSAU]",
  MNA: "BAHRAIN [MANAMA]",
  DHK: "BANGLADESH [DHAKA]",
  BGN: "BARBADOS [BRIDGETOWN]",
  MSK: "BELARUS [MINSK]",
  BRS: "BÉLGICA [BRUXELAS]",
  BLZ: "BELIZE [BELMOPAN]",
  COT: "BENIN [COTONOU]",
  HML: "BERMUDA [HAMILTON]",
  LPZ: "BOLIVIA [LA PAZ]",
  SAR: "BOSNIA-HERZEGOVINA [SARAJEVO]",
  GAB: "BOTSWANA [GABORONE]",
  BSB: "BRUNEI [BANDAR SERI BEGAWAN]",
  SOF: "BULGARIA [SOFIA]",
  OUG: "BURKINA FASO [OUAGADOUGOU]",
  RNG: "BURMA [RANGOON]",
  BUJ: "BURUNDI [BUJUMBURA]",
  PIA: "CABO VERDE [PRAIA]",
  PHP: "CAMBODIA [PHNOM PENH]",
  YDE: "CAMEROON [YAOUNDE]",
  CLG: "CANADA [CALGARY]",
  HLF: "CANADA [HALIFAX]",
  MTL: "CANADA [MONTREAL]",
  OTT: "CANADA [OTTAWA]",
  QBC: "CANADA [QUEBEC]",
  TRT: "CANADA [TORONTO]",
  VAC: "CANADA [VANCOUVER]",
  NDJ: "CHAD [N`DJAMENA]",
  SNT: "CHILE [SANTIAGO]",
  BEJ: "CHINA [BEIJING]",
  GUZ: "CHINA [GUANGZHOU]",
  SHG: "CHINA [SHANGHAI]",
  SNY: "CHINA [SHENYANG]",
  BGT: "COLÔMBIA [BOGOTÁ]",
  BRZ: "CONGO [BRAZZAVILLE]",
  KIN: "CONGO [KINSHASA]",
  SNJ: "COSTA RICA [SAN JOSE]",
  ABJ: "COTE D`IVORIE [ABIDJAN]",
  ZGB: "CROATIA [ZAGREB]",
  HAV: "CUBA [HAVANA]",
  CRC: "CURACAO [CURACAO]",
  NCS: "CHIPRE [NICOSIA]",
  PRG: "REPÚBLICA CHECA [PRAGA]",
  CPN: "DINAMARCA [COPENHAGA]",
  DJI: "DJIBOUTI [DJIBOUTI]",
  SDO: "REPÚBLICA DOMINICANA [SANTO DOMINGO]",
  GYQ: "EQUADOR [GUAYAQUIL]",
  QTO: "EQUADOR [QUITO]",
  CRO: "EGITO [CAIRO]",
  SNS: "EL SALVADOR [SAN SALVADOR]",
  LND: "INGLATERRA [LONDRES]",
  MBO: "EQUATORIAL GUINEA [MALABO]",
  ASM: "ERITREA [ASMARA]",
  TAL: "ESTÔNIA [TALLINN]",
  MBA: "ESWATINI [MBABANE]",
  ADD: "ETIÓPIA [ADDIS ABABA]",
  SUV: "FIJI [SUVA]",
  HLS: "FINLAND [HELSINKI]",
  PRS: "FRANÇA [PARIS]",
  LIB: "GABON [LIBREVILLE]",
  BAN: "GAMBIA [BANJUL]",
  TBL: "GEORGIA [TBILISI]",
  BRL: "ALEMANHA [BERLIM]",
  FRN: "ALEMANHA [FRANKFURT]",
  MUN: "ALEMANHA [MUNICH]",
  ACC: "GHANA [ACCRA]",
  ATH: "GRÉCIA [ATENAS]",
  GTM: "GUATEMALA [CIDADE DA GUATEMALA]",
  CRY: "GUINÉ [CONAKRY]",
  GEO: "GUYANA [GEORGETOWN]",
  PTP: "HAITI [PORT-AU-PRINCE]",
  TGG: "HONDURAS [TEGUCIGALPA]",
  HNK: "HONG KONG]",
  BDP: "HUNGRIA [BUDAPESTE]",
  RKJ: "ISLÂNDIA [REYKJAVIK]",
  MDR: "INDIA [CHENNAI]",
  HYD: "INDIA [HYDERABAD]",
  CLC: "INDIA [KOLKATA]",
  BMB: "ÍNDIA [MUMBAI]",
  NWD: "ÍNDIA [NOVA DELI]",
  JAK: "INDONÉSIA [JAKARTA]",
  SRB: "INDONÉSIA [SURABAYA]",
  BGH: "IRAQUE [BAGHDAD]",
  ERB: "IRAQUE [ERBIL]",
  DBL: "IRELAND [DUBLIN]",
  TLV: "ISRAEL [TEL AVIV]",
  FLR: "ITÁLIA [FLORENÇA]",
  MLN: "ITÁLIA [MILÃO]",
  NPL: "ITÁLIA [NÁPOLES]",
  RME: "ITÁLIA [ROMA]",
  KNG: "JAMAICA [KINGSTON]",
  NHA: "JAPÃO [NAHA]",
  KBO: "JAPÃO [OSAKA / FUKUOKA]",
  TKY: "JAPÃO [TÓQUIO / SAPPORO]",
  JRS: "JERUSALEM]",
  AMM: "JORDAN [AMMAN]",
  ATA: "KAZAKHSTAN [ALMATY]",
  AST: "KAZAKHSTAN [NUR-SULTAN]",
  NRB: "KENYA [NAIROBI]",
  PRI: "KOSOVO [PRISTINA]",
  KWT: "KUWAIT [KUWAIT CITY]",
  BKK: "KYRGYZSTAN [BISHKEK]",
  VNT: "LAOS [VIENTIANE]",
  RGA: "LATVIA [RIGA]",
  BRT: "LÍBANO [BEIRUTA]",
  MAS: "LESOTHO [MASERU]",
  MRV: "LIBERIA [MONROVIA]",
  VIL: "LITUÂNIA [VILNIUS]",
  LXM: "LUXEMBOURG [LUXEMBOURG]",
  ANT: "MADAGASCAR [ANTANANARIVO]",
  LIL: "MALAWI [LILONGWE]",
  KLL: "MALAYSIA [KUALA LUMPUR]",
  BAM: "MALI [BAMAKO]",
  VLL: "MALTA [VALLETTA]",
  MAJ: "MARSHALL ISLANDS [MAJURO]",
  NUK: "MAURITÂNIA [NOUAKCHOTT]",
  PTL: "MAURITIUS [PORT LOUIS]",
  CDJ: "MÉXICO [CIUDAD JUAREZ]",
  GDL: "MÉXICO [GUADALAJARA]",
  HER: "MÉXICO [HERMOSILLO]",
  MTM: "MÉXICO [MATAMOROS]",
  MER: "MÉXICO [MERIDA]",
  MEX: "MÉXICO [CIDADE DO MÉXICO]",
  MTR: "MÉXICO [MONTERREY]",
  NGL: "MÉXICO [NOGALES]",
  NVL: "MÉXICO [NUEVO LAREDO]",
  TJT: "MÉXICO [TIJUANA]",
  KOL: "MICRONESIA [KOLONIA]",
  CHS: "MOLDOVA [CHISINAU]",
  ULN: "MONGOLIA [ULAANBAATAR]",
  POD: "MONTENEGRO [PODGORICA]",
  CSB: "MARROCOS [CASABLANCA]",
  MAP: "MOÇAMBIQUE [MAPUTO]",
  WHK: "NAMÍBIA [WINDHOEK]",
  KDU: "NEPAL [KATHMANDU]",
  AMS: "HOLANDA [AMSTERDÃO]",
  ACK: "NOVA ZELÂNDIA [AUCKLAND]",
  MNG: "NICARAGUA [MANAGUA]",
  NMY: "NIGER [NIAMEY]",
  ABU: "NIGÉRIA [ABUJA]",
  LGS: "NIGÉRIA [LAGOS]",
  SKO: "NORTH MACEDONIA [SKOPJE]",
  BLF: "IRLANDA DO NORTE [BELFAST]",
  OSL: "NORUEGA [OSLO]",
  MST: "OMAN [MUSCAT]",
  ISL: "PAQUISTÃO [ISLAMABAD]",
  KRC: "PAQUISTÃO [KARACHI]",
  KOR: "PALAU [KOROR]",
  PNM: "PANAMÁ [CIDADE DO PANAMÁ]",
  PTM: "PAPUA NOVA GUINÉ [PORTO MORESBY]",
  ASN: "PARAGUAI [ASSUNÇÃO]",
  LMA: "PERU [LIMA]",
  MNL: "FILIPINAS [MANILA]",
  KRK: "POLÔNIA [KRAKOW]",
  WRW: "POLÔNIA [VARSÓVIA]",
  LSB: "PORTUGAL [LISBOA]",
  DOH: "QATAR [DOHA]",
  BCH: "ROMÊNIA [BUCARESTE]",
  MOS: "RÚSSIA [MOSCOU]",
  YEK: "RÚSSIA [YEKATERINBURG]",
  KGL: "RWANDA [KIGALI]",
  APA: "SAMOA [APIA]",
  DHR: "SAUDI ARABIA [DHAHRAN]",
  JDD: "SAUDI ARABIA [JEDDAH]",
  RID: "SAUDI ARABIA [RIYADH]",
  DKR: "SENEGAL [DAKAR]",
  BLG: "SÉRVIA [BELGRADO]",
  FTN: "SIERRA LEONE [FREETOWN]",
  SGP: "CINGAPURA [CINGAPURA]",
  BTS: "SLOVAKIA [BRATISLAVA]",
  LJU: "ESLOVÊNIA [LJUBLJANA]",
  CPT: "ÁFRICA DO SUL [CIDADE DO CABO]",
  DRB: "ÁFRICA DO SUL [DURBAN]",
  JHN: "ÁFRICA DO SUL [JOANESBURGO]",
  SEO: "CORÉIA DO SUL [SEUL]",
  JBA: "SUDÃO DO SUL [JUBA]",
  MDD: "ESPANHA [MADRID]",
  CLM: "SRI LANKA [COLOMBO]",
  KHT: "SUDÃO [KHARTOUM]",
  PRM: "SURINAME [PARAMARIBO]",
  STK: "SUÉCIA [ESTOCOLMO]",
  BEN: "SWITZERLAND [BERN]",
  TAI: "TAIWAN [TAIPEI]",
  DHB: "TAJIQUISTÃO [DUSHANBE]",
  DRS: "TANZÂNIA [DAR ES SALAAM]",
  BNK: "TAILÂNDIA [BANGKOK]",
  CHN: "TAILÂNDIA [CHIANG MAI]",
  DIL: "TIMOR LESTE [DILI]",
  LOM: "TOGO [LOME]",
  PTS: "TRINIDAD [PORTO DA ESPANHA]",
  TNS: "TUNISIA [TUNIS]",
  ANK: "TURQUIA [ANKARA]",
  IST: "TURQUIA [ISTAMBUL]",
  AKD: "TURKMENISTAN [ASHGABAT]",
  KMP: "UGANDA [KAMPALA]",
  KEV: "UCRÂNIA [KYIV]",
  ABD: "EMIRADOS ÁRABES UNIDOS [ABU DHABI]",
  DUB: "EMIRADOS ÁRABES UNIDOS [DUBAI]",
  MTV: "URUGUAI [MONTEVIDÉU]",
  THT: "UZBEKISTAN [TASHKENT]",
  HAN: "VIETNAM [HANOI]",
  HCM: "VIETNAM [HO CHI MINH CITY]",
  LUS: "ZAMBIA [LUSAKA]",
  HRE: "ZIMBABWE [HARARE]",
};

export const AMOUNT_STAY = {
  Y: "Ano(s)",
  M: "Mês(es)",
  W: "Semana(s)",
  D: "Dia(s)",
  H: "Menos de 24 horas",
};

export const OCCUPATIONS = {
  A: "AGRICULTURA",
  AP: "ARTISTA/PERFORMADOR",
  B: "NEGÓCIO",
  CM: "COMUNICAÇÕES",
  CS: "CIÊNCIA DA COMPUTADOR",
  C: "SERVIÇOS CULINÁRIOS / ALIMENTARES",
  ED: "EDUCAÇÃO",
  EN: "ENGENHARIA",
  G: "GOVERNO",
  H: "HOMEMAKER",
  LP: "PROFISSÃO LEGAL",
  MH: "MÉDICO / SAÚDE",
  M: "MILITAR",
  NS: "CIÊNCIA NATURAL",
  N: "NÃO EMPREGADO",
  PS: "CIÊNCIAS FÍSICAS",
  RV: "VOCAÇÃO RELIGIOSA",
  R: "PESQUISA",
  RT: "APOSENTADO",
  SS: "CIÊNCIA SOCIAL",
  S: "ESTUDANTE",
  O: "OUTRO",
};

export const NORMAL_USER = 0;

export const MODERATOR_USER = 1;

export const ADMIN_USER = 2;

export const NORMAL_USER_PERMISSION = "user/isNormal";
export const MODERATOR_USER_PERMISSION = "user/isModerator";
export const ADMIN_USER_PERMISSION = "user/isAdmin";

export const TRAVEL_SUGGESTION = "travel_suggestion";

export const NOT_REQUIRED_PHOTO = ['BRA', 'RDJ', 'SPL'];
