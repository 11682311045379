import { getDefaultState } from "./state";

export default {
  async resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setEmailPasswordMessage(state, emailPasswordMessage = "") {
    state.emailPasswordMessage = emailPasswordMessage;
  },
  setIsLogged(state, isLogged = true) {
    state.isLogged = isLogged;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setIsChecked(state, isChecked = true) {
    state.isChecked = isChecked;
    if (!state.hasChecked && isChecked) state.hasChecked = true;
  },
  updateEmail(state, email) {
    state.user = { ...state.user, email };
  },
  isLoading(state, loading = true) {
    state.isLoading = loading;
  },
};
