<template>
  <a-layout
    :class="[$style.layout, isMobile && !menuCollapsed ? $style.notScrol : '']"
    v-touch:swipe="swipeHandler"
  >
    <TopMenu
      v-if="isMobile ? !menuCollapsed : isNotLoginPage"
      :collapsed="isMobile ? false : !menuCollapsed"
    />
    <a-layout style="width: 100%">
      <a-layout-header :class="$style.header" v-if="isMobile && isNotLoginPage">
        <menu-unfold-outlined
          v-if="menuCollapsed"
          class="trigger"
          @click="toggleCollapse"
        />
        <menu-fold-outlined v-else class="trigger" @click="toggleCollapse" />
      </a-layout-header>
      <a-layout-content :class="[isNotLoginPage ? $style.layoutContent : '']">
        <div :class="[isNotLoginPage ? $style.content : '']">
          <Modals />
          <router-view :key="$route.fullPath" />
        </div>
      </a-layout-content>
      <a-layout-footer
        v-if="isNotLoginPage"
        :class="[isNotLoginPage ? [$style.layoutContent, $style.center] : '']"
      >
        Travelfy © {{ currentYear }}
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import TopMenu from "@/components/TopMenu/TopMenu";
import Modals from "@/components/Modals";
import { mapState, mapMutations } from "vuex";
import { getCompleteUrl } from "@/utils/History";
import { isDesktop, isMobile } from "@/utils/isAgent";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
export default {
  name: "Layout",
  components: {
    TopMenu,
    Modals,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  data() {
    return {
      isNotLoginPage: false,
      isDesktop,
      isMobile,
      showSidebar: true,
    };
  },
  created() {
    this.isNotLoginPage =
      getCompleteUrl() != "/login" && getCompleteUrl() != "/recover-password";
  },
  computed: {
    ...mapState("system", ["menuCollapsed"]),
    currentYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    $route(to) {
      this.isNotLoginPage =
        to.path != "/login" && to.path != "/recover-password" && to.path != "/applications/terms";
    },
  },
  methods: {
    ...mapMutations("system", ["setCollapse"]),
    toggleCollapse() {
      this.setCollapse(!this.menuCollapsed);
    },
    swipeHandler(direction) {
      console.log(direction); // May be left / right / top / bottom
    },
  },
};
</script>

<style module src="./style.scss" lang="scss" />
