export default {
  isLogged(state) {
    return state.user.isLogged;
  },
  isChecked(state) {
    return state.user.isChecked;
  },
  getUser(state) {
    return state.user.user;
  },
  isSubmitting(state) {
    return state.isSubmitting;
  },
};
