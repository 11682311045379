export const getDefaultContent = () => {
  return {
    selectedTravelSuggestion: null,
    passportDocumentNumber: "",
    personal2HoldOtherNationality: "",
    passportAuthorityDocumentCountry: "",
    personal1OtherSurname: [{ value: "" }],
    personal1OtherGivenName: [{ value: "" }],
    personal2HasOtherNationalityPassport: [{ value: "" }],
    personal2HasOtherNationalityPassportNumber: [{ value: "" }],
    personal2OtherNationalityCountry: [{ value: "" }],
    passportHavePassportStolenExplain: [{ value: "" }],
    passportHavePassportStolenDocumentNumber: [{ value: "" }],
    passportHavePassportStolenCountry: [{ value: "" }],
    personal2PermanentResidentOtherCountry: [{ value: "" }],

    familyFormerSpouseSurname: [{ value: "" }],
    familyFormerSpouseGivenName: [{ value: "" }],
    familyFormerSpouseBirthDay: [{ value: "" }],
    familyFormerSpouseBirthMonth: [{ value: "" }],
    familyFormerSpouseBirthYear: [{ value: "" }],
    familyFormerSpouseNationality: [{ value: "" }],
    familyFormerSpouseBirthCity: [{ value: "" }],
    familyFormerSpouseBirthCityNotApply: [{ value: "" }],
    familyFormerSpouseBirthRegion: [{ value: "" }],
    familyFormerSpouseDataMarriageStartDay: [{ value: "" }],
    familyFormerSpouseDataMarriageStartMonth: [{ value: "" }],
    familyFormerSpouseDataMarriageStartYear: [{ value: "" }],
    familyFormerSpouseDataMarriageEndDay: [{ value: "" }],
    familyFormerSpouseDataMarriageEndMonth: [{ value: "" }],
    familyFormerSpouseDataMarriageEndYear: [{ value: "" }],
    familyFormerSpouseEndMarriedExplain: [{ value: "" }],
    familyFormerSpouseCountry: [{ value: "" }],
    familyFormerSpouseBirth: [{ value: "" }],
    familyFormerSpouseDataMarriageStart: [{ value: "" }],
    familyFormerSpouseDataMarriageEnd: [{ value: "" }],

    previousHasTraveledOthersCountries: "",

    previousTraveledOthersCountries: [{ value: "" }],

    travelCompanionsNoPersonGiven: [{ value: "" }],
    travelCompanionsNoPersonSurname: [{ value: "" }],
    travelCompanionsNoPersonRelation: [{ value: "" }],

    travelYesLocationsVisit: [{ value: "" }],

    previousUSTravelHaveBeenUSlengthStay: [{ value: "" }],
    previousUSTravelHaveBeenUSlengthStayType: [{ value: "" }],
    previousArrivalDate: [{ value: "" }],

    previousUSTravelHaveBeenUSHoldDriveLicenseState: [{ value: "" }],

    previousUSTravelHaveBeenUSHoldDriveLicenseNumber: [{ value: "" }],

    addressAndPhoneSocialMediaProvider: [{ value: "NONE" }],

    addressAndPhoneSocialMediaIdentifier: [{ value: "" }],

    previousUSTravelHaveBeenUSDataArrivalDay: [{ value: "" }],
    previousUSTravelHaveBeenUSDataArrivalMonth: [{ value: "" }],
    previousUSTravelHaveBeenUSDataArrivalYear: [{ value: "" }],

    consul: "",
    personal1Surname: "",
    personal1GivenName: "",
    personal1HasUsedOtherName: "",
    personal1Sex: "",
    personal1Marital: "",
    personal1MaritalExplanation: "",
    personal1BirthDay: "",
    personal1BirthMonth: "",
    personal1BirthYear: "",
    personal1BirthCity: "",
    personal1BirthState: "",
    personal1BirthCountry: "",
    personal2Nationality: "",
    personal2IsPermanentResidentOtherCountry: "",
    travelHasMadePlans: "",
    travelYesArrivalDay: "",
    travelYesArrivalMonth: "",
    travelYesArrivalYear: "",
    travelYesArrivalFlight: "",
    travelYesArrivalCity: "",
    travelYesDepartureDay: "",
    travelYesDepartureMonth: "",
    travelYesDepartureYear: "",
    travelYesDepartureFlight: "",
    travelYesDepartureCity: "",
    travelAddressStayLine1: "",
    travelAddressStayLine2: "",
    travelAddressStayCity: "",
    travelAddressStayState: "",
    travelAddressStayZipCode: "",
    travelNoIntentArrivalDay: "",
    travelNoIntentArrivalMonth: "",
    travelNoIntentArrivalYear: "",
    travelNoIntentLengthStay: "",
    travelNoIntentLengthStayType: "",
    travelNoIntentAddressStayLine1: "",
    travelNoIntentAddressStayLine2: "",
    travelNoIntentAddressStayCity: "",
    travelNoIntentAddressStayState: "",
    travelNoIntentAddressStayZipCode: "",
    travelWhoPaying: "",
    travelWhoPayingPersonSurname: "",
    travelWhoPayingPersonGiven: "",
    travelWhoPayingPersonTelephone: "",
    travelWhoPayingPersonEmail: "",
    travelWhoPayingPersonRelation: "",
    travelWhoPayingPersonHasSameAddress: "",
    travelWhoPayingPersonNoAddressLine1: "",
    travelWhoPayingPersonNoAddressLine2: "",
    travelWhoPayingPersonNoAddressCity: "",
    travelWhoPayingPersonNoAddressState: "",
    travelWhoPayingPersonNoAddressZipCode: "",
    travelWhoPayingPersonNoAddressCountry: "",
    travelWhoPayingCompanyName: "",
    travelWhoPayingCompanyTelephone: "",
    travelWhoPayingCompanyRelation: "",
    travelWhoPayingCompanyAddressLine1: "",
    travelWhoPayingCompanyAddressLine2: "",
    travelWhoPayingCompanyAddressCity: "",
    travelWhoPayingCompanyAddressState: "",
    travelWhoPayingCompanyAddressZipCode: "",
    travelWhoPayingCompanyAddressCountry: "",
    travelCompanionsHasPersonTraveling: "",
    travelCompanionsIsInGroup: "",
    travelCompanionsYesGroupName: "",
    previousUSTravelHaveBeenUS: "",
    previousUSTravelHaveBeenUSHoldDriveLicense: "",
    previousUSTravelHaveIssuedBeenUS: "",
    previousUSTravelHaveIssuedBeenUSDateLastVisaWasIssuedDay: "",
    previousUSTravelHaveIssuedBeenUSDateLastVisaWasIssuedMonth: "",
    previousUSTravelHaveIssuedBeenUSDateLastVisaWasIssuedYear: "",
    previousUSTravelHaveIssuedBeenUSDateLastVisaNumber: "",
    previousUSTravelHaveIssuedBeenUSApplyingSameVisa: "",
    previousUSTravelHaveIssuedBeenUSApplyingSameCountry: "",
    previousUSTravelHaveIssuedBeenUSbeenTenPrinted: "",
    previousUSTravelHaveIssuedBeenUSLost: "",
    previousUSTravelHaveIssuedBeenUSLostYear: "",
    previousUSTravelHaveIssuedBeenUSLostExplain: "",
    previousUSTravelHaveIssuedBeenUSCancelled: "",
    previousUSTravelHaveIssuedBeenUSCancelledExplain: "",
    previousUSTravelHaveBeenRefusedAdmission: "",
    previousUSTravelHaveBeenRefusedAdmissionExplain: "",
    previousUSTravelHasFiledImmigrantPetition: "",
    previousUSTravelHasFiledImmigrantPetitionExplain: "",
    addressAndPhoneAddressLine1: "",
    addressAndPhoneAddressLine2: "",
    addressAndPhoneCity: "",
    addressAndPhoneState: "",
    addressAndPhoneZipCode: "",
    addressAndPhoneCountry: "",
    addressAndPhoneEmailAddress: "",
    addressAndPhoneHasOtherEmailsFiveYears: "",
    addressAndPhoneWishProvideInformation: "",
    addressAndPhoneWishProvideInformationAddtionalSocialMediaPlatform: [],
    addressAndPhoneWishProvideInformationAddtionalSocialMediaHandle: [],
    passportDocumentType: "",
    passportBookNumber: "", // nao vai usar
    passportAuthorityDocumentCity: "",
    passportAuthorityDocumentState: "",
    passportAuthorityDocumentRegion: "",
    passportIssuanceDateDay: "",
    passportIssuanceDateMonth: "",
    passportIssuanceDateYear: "",
    passportExpirationDateDay: "",
    passportExpirationDateMonth: "",
    passportExpirationDateYear: "",
    passportHavePassportStolen: "",
    usContactSurname: "",
    usContactGivenName: "",
    usContactNamesNotApply: "",
    usContactOganizationName: "",
    usContactOrganizationNameNotApply: "",
    usContactRelationship: "",
    usContactStreetLine1: "",
    usContactStreetLine2: "",
    usContactCity: "",
    usContactState: "",
    usContactZipCode: "",
    usContactPhoneNumber: "",
    usContactEmail: "",
    usContactEmailNotApply: "",
    familyRelativesFatherSurname: "",
    familyRelativesFatherSurnameNotApply: "",
    familyRelativesFatherGivenName: "",
    familyRelativesFatherGivenNameNotApply: "",
    familyRelativesFatherBirthDay: "",
    familyRelativesFatherBirthMonth: "",
    familyRelativesFatherBirthYear: "",
    familyRelativesFatherBirthNotApply: "",
    familyRelativesFatherIsInUS: "",
    familyRelativesFatherStatus: "",
    familyRelativesMotherSurname: "",
    familyRelativesMotherSurnameNotApply: "",
    familyRelativesMotherGivenName: "",
    familyRelativesMotherGivenNameNotApply: "",
    familyRelativesMotherBirthDay: "",
    familyRelativesMotherBirthMonth: "",
    familyRelativesMotherBirthYear: "",
    familyRelativesMotherBirthNotApply: "",
    familyRelativesMotherIsInUS: "",
    familyRelativesMotherStatus: "",
    familyRelativesHaveImmediateRelatives: "", // nao usa
    familyRelativesImmediateRelativesSurname: [], // nao usa
    familyRelativesImmediateRelativesGivenName: [], // nao usa
    familyRelativesImmediateRelativesRelationship: [], // nao usa
    familyRelativesImmediateRelativesStatus: [], // nao usa
    familyRelativesHaveOtherRelatives: "", // nao usa
    familySpouseSurname: "",
    familySpouseGivenName: "",
    familySpouseBirthDay: "",
    familySpouseBirthMonth: "",
    familySpouseBirthYear: "",
    familySpouseNationality: "",
    familySpouseBirthCity: "",
    familySpouseBirthCityNotApply: "",
    familySpouseBirthCountry: "",
    familySpouseAddress: "", // nao usa
    familySpouseStreetLine1: "", // nao usa
    familySpouseStreetLine2: "", // nao usa
    familySpouseCity: "", // nao usa
    familySpouseState: "", // nao usa
    familySpouseStateNotApply: "", // nao usa
    familySpouseZipCode: "", // nao usa
    familySpouseZipCodeNotApply: "", // nao usa
    familySpouseCountry: "", // nao usa
    workPresentOccupation: "",
    workPresentOccupationExplain: "",
    workPresentEmployerSchoolName: "",
    workPresentStreetLine1: "",
    workPresentStreetLine2: "",
    workPresentCity: "",
    workPresentState: "",
    workPresentZipCode: "",
    workPresentPhoneNumber: "",
    workPresentCountry: "",
    workPresentStartDate: "",
    workPresentStartDateDay: "",
    workPresentStartDateMonth: "",
    workPresentStartDateYear: "",
    workPresentMonthlyIncome: "",
    workPresentBrieflyDescribe: "",

    workPreviousWereYouPreviouslyEmployed: "",
    workPreviousEmployerName: [{ value: "" }],
    workPreviousEmployerStreetLine1: [{ value: "" }],
    workPreviousEmployerStreetLine2: [{ value: "" }],
    workPreviousEmployerCity: [{ value: "" }],
    workPreviousEmployerState: [{ value: "" }],
    workPreviousEmployerZipCode: [{ value: "" }],
    workPreviousEmployerCountry: [{ value: "" }],
    workPreviousEmployerTelephoneNumber: [{ value: "" }],
    workPreviousEmployerJobTitle: [{ value: "" }],
    workPreviousEmployerSupervisorGN: [{ value: "" }],
    workPreviousEmployerSupervisorSN: [{ value: "" }],
    workPreviousEmployerStartDate: [{ value: "" }],
    workPreviousEmployerStartDateDay: [{ value: "" }],
    workPreviousEmployerStartDateMonth: [{ value: "" }],
    workPreviousEmployerStartDateYear: [{ value: "" }],
    workPreviousEmployerEndDate: [{ value: "" }],
    workPreviousEmployerEndDateDay: [{ value: "" }],
    workPreviousEmployerEndDateMonth: [{ value: "" }],
    workPreviousEmployerEndDateYear: [{ value: "" }],
    workPreviousEmployerBrieflyDescribe: [{ value: "" }],

    workPreviousHaveAttendedAnyEducational: "",
    previousEducationInstitutionName: [{ value: "" }],
    previousEducationInstitutionStreetLine1: [{ value: "" }],
    previousEducationInstitutionStreetLine2: [{ value: "" }],
    previousEducationInstitutionCity: [{ value: "" }],
    previousEducationInstitutionState: [{ value: "" }],
    previousEducationInstitutionZipCode: [{ value: "" }],
    previousEducationInstitutionCountry: [{ value: "" }],
    previousEducationInstitutionCourseStudy: [{ value: "" }],
    previousEducationInstitutionStartDate: [{ value: "" }],
    previousEducationInstitutionStartDateDay: [{ value: "" }],
    previousEducationInstitutionStartDateMonth: [{ value: "" }],
    previousEducationInstitutionStartDateYear: [{ value: "" }],
    previousEducationInstitutionEndDate: [{ value: "" }],
    previousEducationInstitutionEndDateDay: [{ value: "" }],
    previousEducationInstitutionEndDateMonth: [{ value: "" }],
    previousEducationInstitutionEndDateYear: [{ value: "" }],
  };
};

export const getDefaultState = () => {
  return {
    applications: [],
    savedApplications: [],
    pagination: {},
    savedPagination: {},
    isLoading: false,
    isSavedLoading: false,
    isLoadingSubmit: false,
    isLoadingPhoto: false,
    isLoadingDelete: false,
    applicationId: undefined,
    application: undefined,
    vForm: getDefaultContent(),
  };
};

const state = { ...getDefaultState(), getDefaultContent };

export default state;
