import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export default {
  mounted() {
    this.detectTouchableDevice();
  },
  methods: {
    detectTouchableDevice() {
      const htmlTag = document.documentElement;
      htmlTag.setAttribute("data-useragent", navigator.userAgent);
      htmlTag.setAttribute("data-platform", navigator.platform);
      if ("ontouchstart" in window || "onmsgesturechange" in window) {
        htmlTag.classList.add("touchable-device");
      }
    },
  },
};
