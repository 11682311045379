import qs from "qs";
import { PARAMS_SERIALIZER_DEFAULT_OPTIONS } from "@/utils/Constants";

history.scrollRestoration = "auto";

/**
 * Remove strings e arrays vazios (mutável)
 * @param {Object} obj
 */
export const cleanObject = (obj) => {
  for (const key in obj) {
    if (obj[key] === "" || (Array.isArray(obj[key]) && !obj[key].length)) {
      delete obj[key];
    }
  }
};

export const getStringifiedQueryParams = (params) => {
  cleanObject(params);
  return Object.keys(params).length
    ? qs.stringify(params, {
        ...PARAMS_SERIALIZER_DEFAULT_OPTIONS,
        addQueryPrefix: true,
      })
    : window.location.pathname;
};

export const getURLQueryParams = () => {
  return qs.parse(window.location.search, {
    ...PARAMS_SERIALIZER_DEFAULT_OPTIONS,
    ignoreQueryPrefix: true,
  });
};

export const getURLQueryParam = (param) => {
  return getURLQueryParams()[param];
};

export const setURLQueryParams = (params) => {
  history.pushState(params, null, getStringifiedQueryParams(params));
};

export const setURLQueryParam = (param) => {
  let params = getURLQueryParams();
  setURLQueryParams(Object.assign(params, param));
};

export const clearURLQueryParams = () => {
  setURLQueryParams({});
};

export const getURLParams = () => {
  return window.location.pathname.replace(/^\/|\/$/g, "").split("/");
};

export const getURLParam = (index) => {
  return getURLParams()[index];
};

export const setURLParams = (params, state = null) => {
  const url =
    "/" +
    params.filter((param) => param && param !== "undefined").join("/") +
    window.location.search;
  history.pushState(state, null, url);
};

export const setURLParam = (index, param, state = null) => {
  let params = getURLParams();
  params[index] = param;
  setURLParams(params, state);
};

export const removeURLParam = (index) => setURLParam(index, null);

export const setURLParamsFromIndex = (index, params, state = null) => {
  const urlParams = getURLParams();
  const urlParamsFromIndex = urlParams.slice(0, index).concat(params);
  setURLParams(urlParamsFromIndex, state);
};

export const changeURL = (path) => {
  let url = `${window.location.origin}${path}`;
  history.pushState(null, null, url);
  window.location.reload();
};

export const getCompleteUrl = () => {
  const { origin, href } = window.location;
  return href.replace(origin, "");
};

export const setCompleteUrl = (url) => {
  history.pushState(null, null, url);
};
