<template>
  <div>
    <UnauthorizedModal v-if="unauthorizedModal" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import UnauthorizedModal from "@/components/Modals/Unauthorized";
export default defineComponent({
  components: {
    UnauthorizedModal,
  },
  computed: {
    ...mapState("modals", ["unauthorizedModal"]),
  },
  mounted() {},
});
</script>
