import { getDefaultState } from "./state";

export default {
  async resetState(state) {
    Object.assign(state, getDefaultState());
  },
  showUnathorizedModal(state, status = true) {
    state.unauthorizedModal = status;
  },
};
