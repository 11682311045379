export const getDefaultState = () => {
  return {
    isLogged: false,
    user: {
      id: "",
      name: "",
      email: "",
      createdAt: "",
    },
    users: [],
    accessToken: null,
    isLoading: false,
    pagination: {},
  };
};

const state = { ...getDefaultState(), isChecked: false, hasChecked: false };

export default state;
