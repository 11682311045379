import api from "@/services/api";

export default {
  resetApplicationCache({ commit, state }) {
    commit("setApplicationId", undefined);
    commit("setApplication", undefined);
    commit("setSaveFilledForm", state.getDefaultContent());
  },
  async getRecharges({ commit }, params = {}) {
    commit("setIsLoading");
    return new Promise((resolve, reject) => {
      return api
        .get("/recharge", { params })
        .then((result) => {
          commit("setIsLoading", false);
          commit("setRecharges", result.data.items);
          commit("setPagination", result.data.meta);
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },

  async populateRechargeChart({ commit }, params = {}) {
    commit("setIsLoading");
    return new Promise((resolve, reject) => {
      return api
        .get("/recharge/chart", { params })
        .then((result) => {
          commit("setIsLoading", false);
          commit("setChart", result.data);
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false, error });
        });
    });
  },
  async createRecharge({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      return api
        .post("/manager/recharge", data)
        .then((response) => {
          resolve(response.data);
          dispatch("recharge/getRecharges", "", { root: true });
        })
        .catch((error) => {
          commit("isLoading", false);
          reject(error);
        });
    });
  },
};
