import { getDefaultState, getDefaultContent } from "./state";

export default {
  async resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSaveFilledForm(state, vForm) {
    state.vForm = { ...getDefaultContent(), ...vForm };
  },
  setApplicationSuggestion(state, suggestion) {
    state.vForm = {
      ...state.vForm,
      ...suggestion.content,
      selectedTravelSuggestion: suggestion.id,
    };
  },
  setApplications(state, applications) {
    state.applications = applications;
  },
  setSavedApplications(state, savedApplications) {
    state.savedApplications = savedApplications;
  },
  setSavedApplicationsPaginated(state, savedApplications) {
    state.savedApplications = [
      ...state.savedApplications,
      ...savedApplications,
    ];
  },
  setApplicationsPaginated(state, applications) {
    state.applications = [...state.applications, ...applications];
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setSavedPagination(state, savedPagination) {
    state.savedPagination = savedPagination;
  },
  clearApplications(state) {
    state.applications = null;
  },
  isLoading(state, loading = true) {
    state.isLoading = loading;
  },
  setApplicationId(state, applicationId) {
    state.applicationId = applicationId;
  },
  setApplication(state, application) {
    state.application = application;
  },
  isSavedLoading(state, loading = true) {
    state.isSavedLoading = loading;
  },
  isLoadingDelete(state, loading = true) {
    state.isLoadingDelete = loading;
  },
  isLoadingPhoto(state, loading = true) {
    state.isLoadingPhoto = loading;
  },
  isLoadingSubmit(state, loading = true) {
    state.isLoadingSubmit = loading;
  },
};
