import { ADMIN_USER, MODERATOR_USER, NORMAL_USER } from "@/utils/Constants";

export default {
  isAdmin(state) {
    return state.user.role == String(ADMIN_USER);
  },
  isModerator(state) {
    return state.user.role == String(MODERATOR_USER);
  },
  isNormal(state) {
    return state.user.role == String(NORMAL_USER);
  },
};
