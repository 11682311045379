import { TRAVEL_SUGGESTION } from "@/utils/Constants";
export default {
  isSystemEnable(state) {
    return state.amountSubscriptionAvailable > 0;
  },
  travelSuggestions(state) {
    return {
      items: state.suggestions.filter((e) => e.type == TRAVEL_SUGGESTION),
      pagination: state.suggestionsPagination,
    };
  },
};
