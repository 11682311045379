<template>
  <a-layout-sider
    v-model:collapsed="menuCollapsed"
    collapsible
    @collapse="collapsedEvent"
    :class="[isMobile ? $style.mobileSider : '']"
    v-touch:swipe="swipeHandler"
  >
    <div :class="$style.logo" />
    <a-menu theme="dark" v-model:selectedKeys="selectedKeys" mode="inline">
      <a-menu-item
        v-for="item in availableRoutes"
        :key="item.path"
        style="background: #011d38"
      >
        <component :is="item.icon" />
        <span>{{ item.name }}</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { defineComponent } from "vue";
import { routes } from "@/router";
import { isMobile } from "@/utils/isAgent";
import { mapState, mapMutations, mapActions } from "vuex";
export default defineComponent({
  name: "TopMenu",
  data() {
    return {
      selectedKeys: [],
      availableRoutes: [],
      isMobile,
    };
  },
  computed: {
    ...mapState("user", ["isLogged"]),
    ...mapState("system", ["menuCollapsed"]),
  },
  created() {
    this.availableRoutes = routes.filter((e) => e.visible());
  },
  watch: {
    selectedKeys() {
      if (this.selectedKeys[0] == "/logout") {
        this.logout();
      } else {
        this.$router.push(this.selectedKeys[0]);

        if (this.menuCollapsed) {
          this.collapsedEvent();
        }
      }
    },
  },
  methods: {
    ...mapMutations("system", ["setCollapse"]),
    ...mapActions("user", ["logout"]),
    collapsedEvent() {
      this.setCollapse(!this.menuCollapsed);
    },
    swipeHandler(direction) {
      console.log(direction); // May be left / right / top / bottom
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
