import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TopMenu from "./components/TopMenu/TopMenu";
import Template from "./partials/Template/Template";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import moment from "moment";
import Maska from "maska";
import Vue2TouchEvents from "vue2-touch-events";

moment.locale("pt-br");

const app = createApp(App);

app.config.productionTip = false;

app.use(store);
app.use(router);
app.use(Antd);
app.use(Maska);
app.use(Vue2TouchEvents);

app.component("top-menu", TopMenu);
app.component("travelfy-template", Template);

app.mount("#app");
