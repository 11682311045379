export default {
  setCollapse(state, menuCollapsed) {
    state.menuCollapsed = menuCollapsed;
  },
  setAmountSubscriptionAvailable(state, value) {
    state.amountSubscriptionAvailable = value;
  },
  setSuggestions(state, value) {
    state.suggestions = value;
  },
  setSuggestionsPagination(state, value) {
    state.suggestionsPagination = value;
  },
  setProducts(state, value) {
    state.products = value;
  },
  isLoading(state, value) {
    state.isLoading = value;
  },
};
