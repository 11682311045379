<template>
  <div></div>
</template>
<script>
import { Modal } from "ant-design-vue";
import { defineComponent } from "vue";
import router from "@/router";
export default defineComponent({
  setup() {
    const countDown = () => {
      let secondsToGo = 5;
      const modal = Modal.error({
        title: "OPS! Você precisa se logar novamente.",
        content: `Estamos te redirecionando em ${secondsToGo} segundos.`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `Estamos te redirecionando em ${secondsToGo} segundos.`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        router.push("/login");
      }, secondsToGo * 1000);
    };

    return {
      countDown,
    };
  },
  mounted() {
    this.countDown();
  },
});
</script>
