const forbiddenPage = import(
  /* webpackChunkName: "forbidden" */
  "../views/forbidden/Forbidden.vue"
);

export const canAccess = (store, permissions = []) => {
  return permissions.some((permission) => {
    return store?.getters[permission];
  });
};

export const hasPermission = (store, importedView, permissions = []) => {
  const granted = permissions.some((permission) => {
    return store?.getters[permission];
  });
  return granted ? importedView : forbiddenPage;
};
