import axios from "axios";
import store from "@/store";
import qs from "qs";
import { PARAMS_SERIALIZER_DEFAULT_OPTIONS } from "@/utils/Constants";
import { cleanObject } from "@/utils/History";
import { httpActions } from "@/utils/httpActions";

/**
 * Api
 * Instância com interceptors:
 * 401 - Abre modal de login
 * 403 - Abre modal de cadastro (caso esteja deslogado)
 * 419 - Atualiza token do usuário
 *
 * Qualquer outro erro mostra uma notificação para o usuário
 */
const token =
  window.sessionStorage.getItem("access_token") ||
  window.localStorage.getItem("vuex")?.user?.access_token;

const sources = ["travelfy.netlify.app", "sistema.travelfybrasil.com"];
const url = sources.includes(window.location.hostname)
  ? "https://api.sistema.travelfybrasil.com"
  : "http://localhost";

  export const apiBaseUrl = `${url}:3000/api/v1`;

const api = axios.create({
  baseURL: apiBaseUrl,
  paramsSerializer: (params) => {
    cleanObject(params);
    return qs.stringify(params, PARAMS_SERIALIZER_DEFAULT_OPTIONS);
  },
  // headers: token ? { Authorization: `Bearer ${token}` } : null,
});

if (token) {
  api.defaults.headers.Authorization = `Bearer ${token}`;
}

api.interceptors.request.use(
  function (request) {
    if (
      !Object.keys(store.state.retry.actionCall).length &&
      Object.keys(store.state.retry.authAction).length
    ) {
      store.commit("retry/setActionCall", store.state.retry.authAction);
    }

    return request;
  },
  function (err) {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response) {
      const { status } = error.response;
      // console.log(status);

      httpActions[status] && httpActions[status](error);

      return Promise.reject(error);
    }
  }
);

export function uploadProgress(progress) {
  return progress
    ? function (progressEvent) {
        return progress(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      }
    : null;
}

/**
 * Cria instância do axios para cancelar
 * chamadas sequenciais
 *
 * Referência: https://github.com/axios/axios/issues/1361#issuecomment-366807250
 */
export function makeRequestCreator() {
  let call = null;
  return (url, params) => {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return api.get(url, { cancelToken: call.token, ...params });
  };
}

/**
 * Amazon
 * Instância limpa sem nossos interceptors
 */
export const amazon = axios.create();

/**
 * Lovense
 * Referência: https://pt.lovense.com/developer/docs/lanlevel
 */
export const lovense = axios.create();

lovense.interceptors.response.use(function (response) {
  const { code } = response.data;

  const errors = {
    500: "http server not started or disabled",
    400: "Invalid Command",
    401: "Toy Not Found",
    402: "Toy Not Connect",
    403: "Toy Not Support This Command",
    404: "Invalid Parameter",
    505: "Other Errors",
    506: "Server Error. Please restart the Body Chat",
  };

  if (code in errors) {
    store.dispatch("notifications/pushErrorToList", {
      message: errors[code],
    });
  }

  return response;
});

export default api;
