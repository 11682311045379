<template>
  <div :div="$style.nav">
    <Layout />
  </div>
</template>

<script>
import Layout from "@/components/Layout/Layout";
export default {
  name: "Template",
  components: {
    Layout,
  },
  props: {
    msg: String,
  },
  data() {
    return {};
  },
};
</script>

<style module src="./style.scss" lang="scss" />
