import {
  WITHOUT_FAMILY,
  STATUS_PROCESSED,
  STATUS_IN_REVIEW,
  STATUS_PROCESSING,
} from "@/utils/Constants";
export default {
  shouldShowFamily(state) {
    return (
      state.vForm.personal1Marital == "" ||
      !WITHOUT_FAMILY.includes(state.vForm.personal1Marital)
    );
  },
  readOnlyForm(state) {
    return (
      state.application?.status == STATUS_PROCESSED || state.application?.status == STATUS_IN_REVIEW || state.application?.status == STATUS_PROCESSING
    );
  },
};
