import {
  IS_DESKTOP,
  IS_FIREFOX,
  IS_IOS,
  IS_MAC,
  IS_MOBILE,
} from "@/utils/Constants";

function isAgent(agent = "desktop") {
  if (!agent) return;

  if (agent === "desktop") {
    const regex = new RegExp(IS_DESKTOP, "i");
    return !!window.navigator.userAgent.match(regex);
  }

  if (agent === "mobile") {
    const regex = new RegExp(IS_MOBILE, "i");
    return !!window.navigator.userAgent.match(regex);
  }

  if (agent === "ios") {
    const regex = new RegExp(IS_IOS, "i");
    return !!window.navigator.userAgent.match(regex);
  }

  if (agent === "macos") {
    const regex = new RegExp(IS_MAC, "i");
    return !!window.navigator.userAgent.match(regex);
  }

  if (agent === "firefox") {
    const regex = new RegExp(IS_FIREFOX, "i");
    return !!window.navigator.userAgent.match(regex);
  }

  if (agent === "android") {
    return /android/i.test(window.navigator.userAgent);
  }

  if (agent === "safari") {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  if (agent === "chrome") {
    return /chrome/i.test(window.navigator.userAgent);
  }

  throw new Error("O parâmetro ainda não tem suporte");
}

export const isWidth = (width, minmax = "max") => {
  if (!width) return;
  if (typeof width === "number") {
    if (minmax === "min") {
      return window.matchMedia(`(min-width: ${width}px)`).matches;
    }
    return window.matchMedia(`(max-width: ${width}px)`).matches;
  }
  return window.matchMedia(width).matches;
};

export const isMobile = isAgent("mobile");
export const isDesktop = isAgent("desktop");
export const isAndroid = isAgent("android");
export const isIos = isAgent("ios");
export const isMacOs = isAgent("macos");
export const isApple = isAgent("macos") || isAgent("ios");
export const isSafari = isAgent("safari");
export const isFirefox = isAgent("firefox");
export const isChrome = isAgent("chrome");

export default isAgent;
