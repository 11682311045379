import { getDefaultState } from "./state";

export default {
  async resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setRecharges(state, recharges) {
    state.recharges = recharges;
  },
  setChart(state, chart) {
    state.chart = {
      ...chart,
      datasets: [
        {
          label: "Pago",
          backgroundColor: "#52c41a",
          data: chart.datasets.paid,
        },
        {
          label: "Reembolso",
          backgroundColor: "#f87979",
          data: chart.datasets.refunded,
        },
        {
          label: "Total Venda (Pago - Reembolso)",
          backgroundColor: "#203e80",
          data: chart.datasets.earn,
        },
      ],
    };
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setIsLoading(state, loading = true) {
    state.isLoading = loading;
  },
};
