export default {
  setIsSubmitting(state, isSubmitting = true) {
    state.isSubmitting = isSubmitting;
  },
  setActiveStore(state, activeStore) {
    state.activeStore = activeStore;
  },
  setHideHeader(state, status) {
    state.hideHeader = status;
  },
  setIsSearching(state, isSearching = true) {
    state.isSearching = isSearching;
  },
};
