export const getDefaultState = () => {
  return {
    recharges: [],
    pagination: {},
    chart: {
      labels: [],
      datasets: [
        {
          label: "Pago",
          backgroundColor: "#52c41a",
          data: [],
        },
        {
          label: "Reembolso",
          backgroundColor: "#f87979",
          data: [],
        },
        {
          label: "Total Venda (Pago - Reembolso)",
          backgroundColor: "#203e80",
          data: [],
        },
      ],
    },
    isLoading: false,
  };
};

const state = { ...getDefaultState() };

export default state;
