import initialState from "./state";

export default {
  setNextRequest(state, nextRequest) {
    state.nextRequest = nextRequest;
  },
  setNextRequestHeaders(state, headers) {
    state.nextRequest.headers = headers;
  },
  clearNextRequest(state) {
    state.nextRequest = initialState.nextRequest;
  },
  setAuthAction(state, authAction) {
    state.authAction = Object.assign({}, authAction);
  },
  clearAuthAction(state) {
    state.authAction = initialState.authAction;
  },
  setActionCall(state, actionCall) {
    state.actionCall = Object.assign({}, actionCall);
  },
  clearActionCall(state) {
    state.actionCall = initialState.actionCall;
  },
};
