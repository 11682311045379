import api from "@/services/api";

export default {
  async getAllSuggestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      return api
        .get("/suggestions", { params: data })
        .then((result) => {
          commit("setSuggestions", result.data.items);
          commit("setSuggestionsPagination", result.data.meta);
          commit("isLoading", false);
          resolve(result.data);
        })
        .catch((error) => {
          commit("isLoading", false);
          reject(error);
        });
    });
  },
  async getAllProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      return api
        .get("/products", data)
        .then((response) => {
          commit("setProducts", response.data);
          commit("isLoading", false);
          resolve(response.data);
        })
        .catch((error) => {
          commit("isLoading", false);
          reject(error);
        });
    });
  },
};
